import React, { useState } from "react";
import { BsDatabaseAdd } from "react-icons/bs";
import Modal from "./Modal";
import { fetchExpenses, getUsers } from "../ApiService";
import ExpensesList from "./ExpensesList";

function Dashboard({ handleLogout }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [expenses, setExpenses] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [users, setUsers] = useState([{}]);
    const [token, setToken] = useState("");

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
            return;
        }
        setUsername(user.username);

        const token = localStorage.getItem("token");
        setToken(token);

        if (!token) {
            return;
        }
        getUsers(token).then((res) => {
            if (res) {
                if (res.error) {
                    return;
                } else {
                    if (res.users) {
                        setUsers(res.users);
                    }
                }
            }
        });

        fetchExpenses(token).then((res) => {
            if (res) {
                if (res.error) {
                    return;
                } else {
                    if (res.expenses) {
                        setExpenses(res.expenses);
                        // check if theres expense off due date
                        res.expenses.forEach((expense) => {
                            const dueDate = new Date(expense.dueDate);
                            const today = new Date();
                            if (dueDate < today) {
                                expense.isExpired = true;
                            } else {
                                expense.isExpired = false;
                            }
                        });
                        setTimeout(() => {
                            setIsLoaded(true);
                        }, 1500);
                    }
                }
            }
        });
    }, []);

    const refreshExpenses = () => {
        setIsLoaded(false);
        fetchExpenses(token).then((res) => {
            if (res) {
                console.log(res);
                if (res.error) {
                    return;
                } else {
                    if (res.expenses) {
                        setExpenses(res.expenses);
                        // check if theres expense off due date
                        res.expenses.forEach((expense) => {
                            const dueDate = new Date(expense.dueDate);
                            const today = new Date();
                            if (dueDate < today) {
                                expense.isExpired = true;
                            } else {
                                expense.isExpired = false;
                            }
                        });
                        setTimeout(() => {
                            setIsLoaded(true);
                        }, 1500);
                    }
                }
            }
        });
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="flex flex-direction-row center buttons w-full">
                <div className="flex flex-direction-row space-between w-100 y-center">
                    <button className="button" onClick={handleOpenModal}>
                        <BsDatabaseAdd className="icon" />
                        Add Expense
                    </button>
                    <div className="flex flex-direction-row y-center">
                        <p className="utente"> {username} </p>
                        <button onClick={handleLogout} className="button small">
                            LOGOUT
                        </button>
                    </div>
                </div>
                {isModalOpen && (
                    <Modal
                        fetchExpenses={fetchExpenses}
                        onClose={handleCloseModal}
                        isModalOpen={isModalOpen}
                        onRefreshExpenses={refreshExpenses}
                    />
                )}
            </div>
            <ExpensesList
                user={username}
                expenses={expenses}
                isLoaded={isLoaded}
                token={token}
                onRefreshExpenses={refreshExpenses}
            />
        </>
    );
}

export default Dashboard;
