import React from "react";
import { HashLoader } from "react-spinners";
import ModalYouSure from "./ModalYouSure";
import {
    BsFillTrash3Fill,
    BsCashCoin,
    BsFillExclamationTriangleFill,
    BsLink45Deg,
    BsXCircle,
} from "react-icons/bs";
function ExpensesList(props) {
    const { expenses, isLoaded, user } = props;
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [selectedExpense, setSelectedExpense] = React.useState(null);
    const [mode, setMode] = React.useState("");
    const [isMobile, setIsMobile] = React.useState(false);

    React.useEffect(() => {
        if (window.innerWidth < 631) {
            setIsMobile(true);
        }
    }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedExpense(null);
    };

    const handlePayButton = (expense) => {
        setSelectedExpense(expense);
        setMode("pay");
        handleOpenModal();
    };

    const handleDeleteButton = (expense) => {
        setSelectedExpense(expense);
        setMode("delete");
        handleOpenModal();
    };

    const handleOpenExpenseModal = (expense) => {
        setSelectedExpense(expense);
        setMode("see");
        handleOpenModal();
    }

    if (!isLoaded) {
        return (
            <div className="container flex x-center flex-direction-column y-center">
                <HashLoader color={"#dcdcdc"} loading={true} size={70} />
            </div>
        );
    }

    if (expenses.length === 0) {
        return (
            <div className="container flex x-center flex-direction-column y-center">
                <h2> No expenses found </h2>
            </div>
        );
    }

    const isUserOwner = (expense) => {
        return expense.addedBy == user;
    };

    return (
        <div className="container y-scroll bg-gray border-round">
            <div className="grid">
                <div className="grid-header">Description</div>
                {!isMobile && <div className="grid-header">Amount</div>}
                <div className="grid-header">Due Date</div>
                {!isMobile && <div className="grid-header">Status</div>}
                {!isMobile && <div className="grid-header">Actions</div>}
                {expenses.map((expense) => (
                    <React.Fragment key={expense.expenseId}>
                        <div className="grid-cell" onClick={()=>{handleOpenExpenseModal(expense)}}>
                            <div
                                className={
                                    isMobile ? (
                                    expense.resolved
                                        ? "flex paid y-center x-center "
                                        : "flex y-center x-center toPay" ) : (
                                            "" )
                                }
                            >
                                {expense.description}
                                <BsLink45Deg></BsLink45Deg>
                            </div>
                        </div>
                        {!isMobile && (
                            <div className="grid-cell">{expense.amount} €</div>
                        )}
                        <div className="grid-cell" onClick={()=>{handleOpenExpenseModal(expense)}}>
                            {new Date(expense.dueDate).toLocaleDateString()}
                        </div>
                        {!isMobile && (
                            <div className="grid-cell">
                                <div
                                    className={
                                        expense.resolved
                                            ? "flex paid y-center x-center "
                                            : "flex y-center x-center toPay"
                                    }
                                >
                                    {expense.isExpired && !expense.resolved ? (
                                        <BsFillExclamationTriangleFill></BsFillExclamationTriangleFill>
                                    ) : (
                                        ""
                                    )}
                                    {expense.resolved
                                        ? "Paid"
                                        : expense.isExpired
                                        ? "Expired"
                                        : "To Pay"}
                                </div>
                            </div>
                        )}
                        {!isMobile && (
                            !isUserOwner(expense) ? (
                            <div className="grid-cell actions">
                                <BsXCircle></BsXCircle>
                            </div>
                            ) : (
                            <div className="grid-cell actions">
                                {!expense.resolved && (
                                    <button
                                        className="flex paidBtn y-center x-center gap-5"
                                        onClick={() => handlePayButton(expense)}
                                    >
                                        <BsCashCoin></BsCashCoin>
                                        Pay
                                    </button>
                                )}
                                <button
                                    className="flex deleteBtn y-center x-center gap-5"
                                    onClick={() => handleDeleteButton(expense)}
                                >
                                    <BsFillTrash3Fill></BsFillTrash3Fill>
                                    Delete
                                </button>
                            </div>
                            )
                        )}
                    </React.Fragment>
                ))}
            </div>
            {isModalOpen && (
                <ModalYouSure
                    mode={mode}
                    setMode={setMode}
                    token={props.token}
                    onClose={handleCloseModal}
                    isModalOpen={isModalOpen}
                    selectedExpense={selectedExpense}
                    onRefreshExpenses={props.onRefreshExpenses}
                    isOwner={isUserOwner(selectedExpense)}
                />
            )}
        </div>
    );
}

export default ExpensesList;
