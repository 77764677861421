import React from "react";
import Dashboard from "../components/Dashboard";
import { useNavigate } from "react-router-dom";
import { isTokenValid, healthCheck } from "../ApiService";

function Home() {
    const [isServerDown, setIsServerDown] = React.useState(false);

    const nav = useNavigate();

    React.useEffect(() => {
        healthCheck().then((res) => {
            if (!res) {
                setIsServerDown(true);
            } else {
                setIsServerDown(false);
            }
        });
    }, []);

    React.useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            return nav("/login");
        }
        isTokenValid(token).then((res) => {
            if (!res) {
                localStorage.removeItem("token");
                return nav("/login");
            }
        });
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload();
    };

    if (isServerDown) {
        return (
            <div className="flex fullPage x-center flex-direction-column y-center">
                <div className="flex space-between">
                    <div>
                        <h2> Unlucky Studio - Expenses </h2>
                    </div>
                </div>
                <div className="flex flex-direction-column center fullPage">
                    <h3> Server is down, lol </h3>
                    <p> Please try again later </p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex fullPage x-center flex-direction-column y-center">
            <div className="flex space-between">
                <div>
                    <h2> Unlucky Studio - Expenses</h2>
                </div>
            </div>
            <Dashboard handleLogout={handleLogout}/>
        </div>
    );
}

export default Home;
