import React from "react";

function Nopage() {
    return (
        <div className="flex fullPage center flex-direction-column">
            <h1> 404 - Page Not Found </h1>
            <a href="/" className="white"> Return Home </a>
        </div>
    );
}

export default Nopage;
