import React from "react";
import { useState, useEffect, useRef } from "react";
import { addExpense, setExpeseAsPaid, deleteExpense } from "../ApiService";

function ModalYouSure(props) {
    const {
        onClose,
        isModalOpen,
        mode,
        selectedExpense,
        token,
        onRefreshExpenses,
        setMode,
        isOwner,
    } = props;
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const handleOnClick = async () => {
        if (mode === "pay") {
            const UPDATED = await setExpeseAsPaid(
                selectedExpense.expenseId,
                token
            );
            if (UPDATED) {
                onRefreshExpenses();
            }
        } else if (mode === "delete") {
            const DELETED = await deleteExpense(
                selectedExpense.expenseId,
                token
            );
            if (DELETED) {
                onRefreshExpenses();
            }
        }
        onClose();
    };

    if (mode === "pay") {
        return (
            <div className={`black modal-wrapper ${isModalOpen ? "open" : ""}`}>
                <div className="modal" ref={modalRef}>
                    <div className="modal-content">
                        <h2>Set this as paid ?</h2>
                        <div className="flex flex-direction-column expenseCard">
                            <p>Description: {selectedExpense.description}</p>
                            <p>Amount: {selectedExpense.amount}</p>
                            <p>
                                Due Date:{" "}
                                {new Date(
                                    selectedExpense.dueDate
                                ).toLocaleDateString()}
                            </p>
                        </div>
                        <div className="flex flex-direction-row center buttons w-full gap-15">
                            <button
                                className="button small"
                                onClick={handleOnClick}
                            >
                                Yes
                            </button>
                            <button className="button small" onClick={onClose}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (mode === "delete") {
        return (
            <div className={`black modal-wrapper ${isModalOpen ? "open" : ""}`}>
                <div className="modal" ref={modalRef}>
                    <div className="modal-content">
                        <h2>Delete this ?</h2>
                        <div className="flex flex-direction-column expenseCard">
                            <p>Description: {selectedExpense.description}</p>
                            <p>Amount: {selectedExpense.amount}</p>
                            <p>
                                Due Date:{" "}
                                {new Date(
                                    selectedExpense.dueDate
                                ).toLocaleDateString()}
                            </p>
                        </div>
                        <div className="flex flex-direction-row center buttons w-full gap-15">
                            <button
                                className="button small"
                                onClick={handleOnClick}
                            >
                                Yes
                            </button>
                            <button className="button small" onClick={onClose}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (mode === "see") {
        return (
            <div className={`black modal-wrapper ${isModalOpen ? "open" : ""}`}>
                <div className="modal" ref={modalRef}>
                    <div className="modal-content">
                        <h2>Expense</h2>
                        <div className="flex flex-direction-column secondGrid expenseCard">
                            <p className="grid-header-2"> Added By: </p>
                            <p className="grid-cell-2">
                                {" "}
                                {selectedExpense.addedBy}{" "}
                            </p>
                            <p className="grid-header-2"> Description: </p>
                            <p className="grid-cell-2">
                                {" "}
                                {selectedExpense.description}{" "}
                            </p>
                            <p className="grid-header-2"> Total: </p>
                            <p className="grid-cell-2">
                                {" "}
                                {selectedExpense.amount} €{" "}
                            </p>
                            <p className="grid-header-2"> Amount each: </p>
                            <p className="grid-cell-2">
                                {selectedExpense.description.toLowerCase() ===
                                "affitto"
                                    ? (selectedExpense.amount / 4).toFixed(2) +
                                      " €"
                                    : (selectedExpense.amount / 3).toFixed(2) +
                                      " €"}
                            </p>
                            <p className="grid-header-2">Due Date: </p>
                            <p className="grid-cell-2">
                                {new Date(
                                    selectedExpense.dueDate
                                ).toLocaleDateString()}
                            </p>
                            <p className="grid-header-2">Paid: </p>
                            <p className="grid-cell-2">
                                {selectedExpense.resolved ? "Yes" : "No"}
                            </p>
                            <p className="grid-header-2">Notes: </p>
                            <p className="grid-cell-2">
                                {" "}
                                {selectedExpense.notes}{" "}
                            </p>
                        </div>
                        <div className="flex flex-direction-row center buttons w-full gap-15">
                            {isOwner
                                ? !selectedExpense.resolved && (
                                      <button
                                          className="button small"
                                          onClick={() => {
                                              props.setMode("pay");
                                          }}
                                      >
                                          Pay
                                      </button>
                                  )
                                : null}
                            {isOwner && (
                                <button
                                    className="button small"
                                    onClick={() => {
                                        props.setMode("delete");
                                    }}
                                >
                                    Delete
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <h1>Error</h1>
        </>
    );
}

export default ModalYouSure;
