import React from "react";
import { useNavigate } from "react-router-dom";
import { registerFunction } from "../ApiService";

function Register() {
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const [passwordsDontMatch, setPasswordsDontMatch] = React.useState(false);
    const [usernameTaken, setUsernameTaken] = React.useState(false);
    const [emailAlreadyTaken, setEmailAlreadyTaken] = React.useState(false);
    const [emailIncorrect, setEmailIncorrect] = React.useState(false);
    const navigate = useNavigate();

    const resetErrors = () => {
        setPasswordsDontMatch(false);
        setUsernameTaken(false);
        setEmailAlreadyTaken(false);
    }

    const clearForm = (e) => {
        const form = e.target;
        const usernameField = form.querySelector('input[name="username"]');
        const emailField = form.querySelector('input[name="email"]');
        const passwordFields = form.querySelectorAll('input[type="password"]');
        usernameField.value = "";
        emailField.value = "";
        passwordFields.forEach((field) => {
            field.value = "";
        });
        usernameField.focus();
    };


    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const username = data.get("username");
        const email = data.get("email");
        const password = data.get("password");
        const password2 = data.get("password2");
        if (password !== password2) {
            resetErrors();
            setPasswordsDontMatch(true);
            const passwordFields = e.target.querySelectorAll(
                'input[type="password"]'
            );
            passwordFields.forEach((field) => {
                field.value = "";
            });
            passwordFields[0].focus();
            return;
        } 
        if (!email.match(validEmailRegex)) {
            resetErrors();
            setEmailIncorrect(true);
            const emailField = e.target.querySelector(
                'input[name="email"]'
            );
            const passwordFields = e.target.querySelectorAll(
                'input[type="password"]'
            );
            passwordFields.forEach((field) => {
                field.value = "";
            });
            emailField.value = "";
            emailField.focus();
            return;
        }

        registerFunction({ username, password, email }).then((data) => {
            resetErrors();
            if (data.error === "username already exists") {
                setUsernameTaken(true);
                clearForm(e);
                return;
            } else if (data.error === "email already exists") {
                setEmailAlreadyTaken(true);
                clearForm(e);
                return;
            } else {
                resetErrors();
                // set user in local storage (username and houseId) and dispatch info
                localStorage.setItem("token", data.token);
                localStorage.setItem("user", JSON.stringify(data.user));
                // redirect to home page
                navigate(`/`);
            }
        });
    };
    return (
        <div className="flex fullPage x-center flex-direction-column y-center">
            <div className="flex flex-direction-column center fullPage">
                <form onSubmit={handleRegisterSubmit} className="flex flex-direction-column gap-15 w-100 x-center y-center loginForm">
                    <h3> REGISTER </h3>
                    <input
                        className="input"
                        type="text"
                        id="username"
                        name="username"
                        placeholder="Username..."
                        required
                    />
                    <input
                        className="input"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email..."
                        required
                    />
                    <input
                        className="input"
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password..."
                        required
                    />
                    <input
                        className="input"
                        type="password"
                        id="password2"
                        name="password2"
                        placeholder="Confirm Password..."
                        required
                    />
                    {passwordsDontMatch && (
                        <p className="error">Passwords don't match!</p>
                    )}
                    {usernameTaken && (
                        <p className="error">Username already taken!</p>
                    )}
                    {emailAlreadyTaken && (
                        <p className="error">Email already taken!</p>
                    )}
                    <hr></hr>
                    <input className="button" type="submit" value="Register" />
                    <p className="m-15">Already have an account? <a href="/login">Login</a></p>
                </form>
            </div>
        </div>
    );
}

export default Register;
