import React, { useState, useRef, useEffect } from "react";
import { addExpense } from "../ApiService";

const Modal = ({ onClose, isModalOpen, onRefreshExpenses }) => {
    const [what, setWhat] = useState("");
    const [amount, setAmount] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [notes, setNotes] = useState("");

    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case "what":
                setWhat(value);
                break;
            case "amount":
                setAmount(value);
                break;
            case "dueDate":
                setDueDate(value);
                break;
            case "notes":
                setNotes(value);
                break;
            default:
                break;
        }
    };

    const handleAddClick = async () => {
        let stringAmount = amount;
        if (!what || !amount || !dueDate) {
            alert("Please enter all fields");
            return;
        }

        // if the description is too long, alert the user
        if (what.length > 13) {
            alert("Description must be less than 13 characters");
            setWhat("");
            return;
        }

        // if the number is with the comma, replace it with a dot
        if (stringAmount.includes(",")) {
            stringAmount = stringAmount.replace(",", ".");
        }
        const amountNumber = Number(stringAmount);
        if (isNaN(amountNumber)) {
            alert("Amount must be a number");
            return;
        }

        // check if dueDate is a date
        if (isNaN(Date.parse(dueDate))) {
            alert("Due Date must be a date");
            return;
        }
        
        // take user from localStorage
        const user = JSON.parse(localStorage.getItem("user"));
        // take the date of today in milliseconds
        const today = new Date();
        // create an object with all the data
        const expenseObject = {
            user: user.username,
            date: today.getTime(),
            what,
            amount: stringAmount,
            dueDate,
            notes,
        };
        const token = localStorage.getItem("token");
        const added = await addExpense(expenseObject, token);
        // add confirmation message (TODO: add a toast message)
        if (added) {
            onRefreshExpenses();
        } else {
            alert("Error adding expense, try again");
        }

        // reset input values
        setWhat("");
        setAmount("");
        setDueDate("");
        setNotes("");
        onClose();
    };

    return (
        <div className={`black modal-wrapper ${isModalOpen ? 'open' : ''}`}>
            <div className="modal" ref={modalRef}>
                <div className="modal-content">
                    <h2>Add Expense</h2>
                    <form className="flex flex-direction-column m-15 gap-15 w-100 x-center y-center"> 
                        <input type="text" id="what" name="what" placeholder="What.. (Ex: Luce)" onChange={handleInputChange} required/>
                        <input type="text" id="amount" name="amount" placeholder="Amount.. (Ex: 176,55)" onChange={handleInputChange} required/>
                        <input type="date" id="dueDate" name="dueDate" placeholder="Due Date..." onChange={handleInputChange} required/>
                        <input type="text" id="notes" name="notes" placeholder="Notes..." onChange={handleInputChange}/>
                    </form>
                    <div className="flex flex-direction-row center buttons w-full gap-15">
                        <button className="button small" onClick={handleAddClick}>Add</button>
                        <button className="button small" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
