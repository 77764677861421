const BASE_URL = process.env.REACT_APP_BASE_URL;

const addExpense = async (expenseObject, token) => {
    try {
        const response = await fetch(`${BASE_URL}/expense/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(expenseObject),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return false;
    }
};

const isTokenValid = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}/validateToken`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const loginFunction = async (user) => {
    try {
        const response = await fetch(`${BASE_URL}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(user),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
    }
};

const registerFunction = async (user) => {
    return fetch(`${BASE_URL}/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch((err) => console.log(err));
};

const fetchExpenses = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}/expenses`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
    }
};

const setExpeseAsPaid = async (expenseId, token) => {
    try {
        const response = await fetch(`${BASE_URL}/expense/${expenseId}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return true;
    } catch (error) {
        return false;
    }
};

const deleteExpense = async (expenseId, token) => {
    try {
        const response = await fetch(`${BASE_URL}/expense/${expenseId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return true;
    } catch (error) {
        return false;
    }
};

const healthCheck = async () => {
    try {
        const url = `${BASE_URL}/health`;
        const response = await fetch(`${BASE_URL}/health`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        if (data == true) {
            return true;
        }
    } catch (error) {
        return false;
    }
};

const getUsers = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}/users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
    }
};

export {
    addExpense,
    isTokenValid,
    loginFunction,
    registerFunction,
    fetchExpenses,
    setExpeseAsPaid,
    deleteExpense,
    healthCheck,
    getUsers,
};
